import Control from '@peckadesign/pd-naja/dist/utils/Control'
import { tns } from 'tiny-slider/src/tiny-slider'
import { TinySliderInstance, TinySliderSettings } from 'tiny-slider'
import React from 'jsx-dom'
import { deepMerge } from 'collapsable.js'

interface HTMLCarouselElement extends HTMLElement {
	carousel: TinySliderInstance
}

class CarouselControl implements Control {
	public initialize(context: Element | Document): void {
		const carousels = context.querySelectorAll<HTMLElement>('.js-carousel')

		carousels.forEach((carouselWrapper) => {
			const carouselElement = carouselWrapper.querySelector<HTMLElement>('.js-carousel__carousel')
			const itemsCount = carouselElement ? carouselElement.querySelectorAll('.js-carousel__item').length : 0

			if (itemsCount <= 1 || !carouselElement) {
				return
			}

			const dataOptions = JSON.parse(carouselWrapper.dataset.carousel || '{}')
			const controls = this.buildControlsContainer(carouselWrapper, carouselElement)
			const nav = this.buildNavContainer(carouselWrapper, itemsCount)

			const defaultOptions: TinySliderSettings = {
				container: carouselElement,
				controlsContainer: controls,
				navContainer: nav,
				loop: true
			}

			const options = deepMerge({}, defaultOptions, dataOptions)

			;(carouselWrapper as HTMLCarouselElement).carousel = tns(options)
		})
	}

	private buildControlsContainer(carouselWrapper: HTMLElement, carouselElement: HTMLElement): HTMLElement {
		const controls = carouselWrapper.querySelector<HTMLElement>('.js-carousel__controls')

		if (controls) {
			controls.hidden = false
			return controls
		}

		const customControls: Element = (
			<p class="js-carousel__controls">
				<button type="button" class="sr-only">
					Předchozí
				</button>
				<button type="button" class="btn btn--secondary absolute top-1/4 right-0 z-1 w-5 h-5 min-h-0 p-0 shadow-none">
					<span class="btn__inner btn__inner--text">
						<i class="icon icon--arrow-right" aria-hidden="true"></i>
						<span class="sr-only">Další</span>
					</span>
				</button>
			</p>
		)

		carouselElement.insertAdjacentElement('beforebegin', customControls)

		return customControls as HTMLElement
	}

	private buildNavContainer(carouselWrapper: HTMLElement, itemsCount: number): HTMLElement {
		const nav = carouselWrapper.querySelector<HTMLElement>('.js-carousel__nav')

		if (nav) {
			nav.hidden = false
			return nav
		}

		const customNav: Element = <p class="flex justify-center gap-1"></p>

		for (let i = 0; i < itemsCount; i++) {
			customNav.appendChild(
				<button
					type="button"
					class="h-[7px] rounded-full bg-blue/10 transition-all js-carousel__page"
					style="display: none"
				></button>
			)
		}

		carouselWrapper.insertAdjacentElement('beforeend', customNav)

		return customNav as HTMLElement
	}
}

export default new CarouselControl()
