import Control from '@peckadesign/pd-naja/dist/utils/Control'

class AutosubmitControl implements Control {
	private formSelector = '.js-autosubmit'
	private submitSelector = '[name="autosubmit"], .js-autosubmit__submit'
	private inputSelectorArray = [
		'input:not([type="button"], [type="submit"], [type="reset"], .js-autosubmit__off)',
		'textarea:not(.js-autosubmit__off)',
		'select:not(.js-autosubmit__off)'
	]

	public initialize(): void {
		const selector = this.inputSelectorArray.map((inputSelect) => `${this.formSelector} ${inputSelect}`).join(', ')

		document.addEventListener('change', (event) => {
			if (!event.target || !(event.target as Element).matches(selector)) {
				return
			}

			const form = (event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement).form
			const submit = form?.querySelector<HTMLButtonElement>(this.submitSelector)

			submit?.click()
		})
	}
}

export default new AutosubmitControl()
