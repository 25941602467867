export interface FacultyGuideState {
	country: string | null
	city: string | null
	university: string | null
}

const LEVEL_NO_SELECTION = 0
const LEVEL_COUNTRY_SELECTED = 1
const LEVEL_CITY_SELECTED = 2
const LEVEL_UNIVERSITY_SELECTED = 3

interface FacultyGuideSelect {
	level: number
	select: HTMLSelectElement
}

export class FacultyGuide {
	private facultyGuideElement: HTMLElement
	private facultyGuideForm: HTMLFormElement

	private readonly cssVariableName = '--faculty-guide-level'

	private currentLevel: number
	private popstateLevel: number | undefined
	private readonly minLevel: number = LEVEL_NO_SELECTION
	private readonly maxLevel: number = LEVEL_UNIVERSITY_SELECTED
	private readonly cssMinLevel: number = LEVEL_COUNTRY_SELECTED

	private selects: FacultyGuideSelect[]
	private state: FacultyGuideState = {
		country: null,
		city: null,
		university: null
	}

	public constructor(element: HTMLElement) {
		this.facultyGuideElement = element
		const facultyGuideForm = element.querySelector('form')

		if (!facultyGuideForm) {
			throw new Error('Faculty Guide is missing form element.')
		}

		this.currentLevel = LEVEL_NO_SELECTION

		this.facultyGuideForm = facultyGuideForm
		this.selects = [
			{
				level: LEVEL_COUNTRY_SELECTED,
				select: this.facultyGuideForm.elements.namedItem('country') as HTMLSelectElement
			},
			{
				level: LEVEL_CITY_SELECTED,
				select: this.facultyGuideForm.elements.namedItem('city') as HTMLSelectElement
			},
			{
				level: LEVEL_UNIVERSITY_SELECTED,
				select: this.facultyGuideForm.elements.namedItem('university') as HTMLSelectElement
			}
		]

		this.setState()
		this.setLevelFromState(this.state)
	}

	private setLevelFromState(state: FacultyGuideState): void {
		if (state.country === null) {
			this.setLevel(LEVEL_NO_SELECTION)
		} else if (state.city === null) {
			this.setLevel(LEVEL_COUNTRY_SELECTED)
		} else if (state.university === null) {
			this.setLevel(LEVEL_CITY_SELECTED)
		} else {
			this.setLevel(LEVEL_UNIVERSITY_SELECTED)
		}
	}

	public updateState(state: FacultyGuideState): void {
		this.setSelectValuesFromState(state)
		this.setLevelFromState(state)

		this.setState()
	}

	private setLevel(level: number): void {
		this.currentLevel = Math.max(Math.min(level, this.maxLevel), this.minLevel)
		this.processSelectsState()
		this.updateCssVariables()
	}

	private processSelectsState(): void {
		this.selects.forEach(({ level, select }) => {
			if (this.currentLevel < level) {
				this.resetSelect(select)
			}

			select.disabled = this.currentLevel + 1 < level
		})
	}

	private resetSelect(select: HTMLSelectElement): void {
		select.value = ''
		select.dispatchEvent(new Event('update', { bubbles: true }))
	}

	private setSelectValuesFromState(state: FacultyGuideState): void {
		this.selects.forEach(({ select }) => {
			if (!(select.name in state)) {
				return
			}

			const value = state[select.name as keyof FacultyGuideState]
			select.value = value === null ? '' : value
			select.dispatchEvent(new Event('update', { bubbles: true }))
		})
	}

	private updateCssVariables(): void {
		this.facultyGuideElement.style.setProperty(
			this.cssVariableName,
			String(Math.max(this.currentLevel, this.cssMinLevel))
		)
	}

	private setState(): void {
		this.selects.forEach(({ select }) => {
			this.state[select.name as keyof FacultyGuideState] = select.value === '' ? null : select.value
		})
	}

	public getState(): FacultyGuideState {
		return this.state
	}
}
