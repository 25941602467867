import { CompleteEvent, Extension, Naja, Options } from 'naja/dist/Naja'
import { InteractionEvent } from 'naja/dist/core/UIHandler'

declare module 'naja/dist/Naja' {
	interface Options {
		categoryPillsPreviousActive?: Element
		categoryPillsActive?: Element
	}
}

export class CategoryPillsExtension implements Extension {
	private readonly activeClass = 'active'

	public initialize(naja: Naja) {
		naja.uiHandler.addEventListener('interaction', this.checkExtensionEnabled.bind(this))

		naja.addEventListener('complete', this.restorePreviousActivePill.bind(this))
	}

	private checkExtensionEnabled(event: InteractionEvent): void {
		const { element, options } = event.detail

		if (element.matches('[data-naja-category-pills]')) {
			this.changeActivePill(element, options)
		}
	}

	private changeActivePill(element: Element, options: Options): void {
		const activePill = element.parentElement?.querySelector('.active')

		options.categoryPillsPreviousActive = activePill || undefined
		options.categoryPillsActive = element

		activePill?.classList.remove(this.activeClass)
		element.classList.add(this.activeClass)
	}

	private restorePreviousActivePill(event: CompleteEvent): void {
		if (!event.detail.error) {
			return
		}

		const { options } = event.detail

		if (options.categoryPillsPreviousActive) {
			options.categoryPillsPreviousActive.classList.add(this.activeClass)
		}

		if (options.categoryPillsActive) {
			options.categoryPillsActive.classList.remove(this.activeClass)
			;(options.categoryPillsActive as HTMLElement).blur()
		}
	}
}
