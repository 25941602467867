import PdModal from '@peckadesign/pd-modal'
import { Spinner } from '@/js/App/Spinner'
import React from 'jsx-dom'

export const modal = new PdModal({
	className: 'prose',
	spinner: Spinner({}),
	template: function (this: PdModal): HTMLElement {
		const i18n = this.i18n[this.options.language]

		return (
			<div
				class="pd-modal"
				id="pdModal"
				aria-hidden="true"
				aria-labelledby="snippet--pdModalTitle"
				aria-describedby="snippet--pdModalPerex"
			>
				<div class="pd-modal__overlay" data-a11y-dialog-hide={true}></div>
				<div class="pd-modal__window" role="document">
					<div class="pd-modal__dialog ajax-wrap">
						<header class="pd-modal__header container">
							<button type="button" data-a11y-dialog-hide={true} class="pd-modal__close" aria-label={i18n.close}>
								{i18n.close}
							</button>

							<h1 class="pd-modal__title" id="snippet--pdModalTitle">
								{i18n.defaultTitle}
							</h1>

							<p id="snippet--pdModalPerex" class="w-full font-bold empty:hidden"></p>
						</header>

						<div class="pd-modal__content container" id="snippet--pdModal"></div>
					</div>
				</div>
			</div>
		) as HTMLElement
	}
})

modal.addEventListener('beforeOpen', () => {
	const perex = modal.window.querySelector('#snippet--pdModalPerex')

	if (!perex) {
		return
	}

	perex.innerHTML = ''
})
