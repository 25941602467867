import AutosubmitControl from '@/js/Controls/AutosubmitControl'
import BottomStuck from '@/js/Controls/BottomStuck'
import CarouselControl from '@/js/Controls/CarouselControl'
import CheckboxGroupControl from '@/js/Controls/CheckboxGroupControl'
import CollapsableControl from '@/js/Controls/CollapsableControl'
import EqualHeightsControl from '@/js/Controls/EqualHeightsControl'
import FormFloatingControl from '@/js/Controls/FormFloatingControl'
import ScrollToActive from '@/js/Controls/ScrollToActive'
import TooltipControl from '@/js/Controls/TooltipControl'
import { controlManager } from '@peckadesign/pd-naja'

controlManager.addControlOnLoad(AutosubmitControl)
controlManager.addControlOnLoad(ScrollToActive)

controlManager.addControlOnLive(BottomStuck)
controlManager.addControlOnLive(CarouselControl)
controlManager.addControlOnLive(CheckboxGroupControl)
controlManager.addControlOnLive(CollapsableControl)
controlManager.addControlOnLive(EqualHeightsControl)
controlManager.addControlOnLive(FormFloatingControl)
controlManager.addControlOnLive(TooltipControl)

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', () => controlManager.onLoad())
} else {
	controlManager.onLoad()
}
