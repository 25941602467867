import Control from '@peckadesign/pd-naja/dist/utils/Control'

class ScrollToActive implements Control {
	public initialize(context: Element | Document): void {
		const elements = context.querySelectorAll<HTMLElement>('.js-scroll-to-active')

		elements.forEach((element) => {
			const active = element.querySelector<HTMLElement>('.active')

			if (active) {
				// Cannot use `active.scrollIntoView()`, beucase that would scroll with the entire page, we only want to
				// horizontally scroll with `element`
				element.scrollLeft = active.offsetLeft - (element.clientWidth - active.clientWidth) / 2
			}
		})
	}
}

export default new ScrollToActive()
