class Filter {
	private static filterUid = 0
	private static getFilterUid = () => `filter-uid-${Filter.filterUid++}`

	private static readonly CATEGORY_ALL = 'all'
	private static readonly CATEGORY_UNKNOWN = 'unsorted'

	private element: HTMLElement
	private items: HTMLElement[]
	private buttons: NodeListOf<HTMLElement>

	private categories: Map<string, HTMLElement[]> = new Map()

	public constructor(element: HTMLElement) {
		this.element = element

		this.buttons = this.element.querySelectorAll<HTMLElement>('.js-filter__button')
		this.items = Array.from(this.element.querySelectorAll<HTMLElement>('.js-filter__item'))

		this.buttons.forEach((button) => this.initializeButton(button))
		this.items.forEach((item) => this.toggleItem(item, true))
	}

	private initializeButton(button: HTMLElement): void {
		const category = this.getButtonCategory(button)
		const categories = this.getItemsByCategory(category)

		categories.forEach((category) => {
			if (!category.id) {
				category.id = Filter.getFilterUid()
			}
		})

		this.categories.set(category, categories)

		button.setAttribute('aria-controls', this.getItemsIdsByCategory(category).join(' '))
		button.addEventListener('click', (event) => this.filter(event, button, category))

		this.toggleButton(button, category === Filter.CATEGORY_ALL)

		if (category === Filter.CATEGORY_ALL) {
			return
		}
	}

	private getButtonCategory(button: HTMLElement): string {
		return button.dataset.filterCategory || Filter.CATEGORY_UNKNOWN
	}

	private getItemCategories(item: HTMLElement): string[] {
		return item.dataset.filterCategory?.split(' ') || [Filter.CATEGORY_UNKNOWN]
	}

	private getItemsByCategory(category: string): HTMLElement[] {
		return this.items.filter((item) => this.getItemCategories(item).includes(category))
	}

	private getItemsIdsByCategory(category: string): string[] {
		if (category === Filter.CATEGORY_ALL) {
			return this.items.map((item) => item.id)
		} else {
			return this.getItemsByCategory(category).map((item) => item.id)
		}
	}

	private filter(event: Event, activeButton: HTMLElement, category: string): void {
		event.preventDefault()

		const activeItems = this.categories.get(category) || []

		this.buttons.forEach((button) => this.toggleButton(button, button === activeButton))
		this.items.forEach((item) => this.toggleItem(item, category === Filter.CATEGORY_ALL || activeItems.includes(item)))
	}

	private toggleButton(button: HTMLElement, active: boolean): void {
		button.ariaExpanded = String(active)
		button.classList.toggle('active', active)
	}

	private toggleItem(item: HTMLElement, visible: boolean): void {
		item.ariaHidden = String(!visible)
		item.classList[visible ? 'remove' : 'add']('hidden')
	}
}

const filterElements = document.querySelectorAll<HTMLElement>('.js-filter')
filterElements.forEach((element) => new Filter(element))
