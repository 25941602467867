import React, { Fragment, ReactNode } from 'jsx-dom'
import { ConditionalWrappers } from '@/js/helpers/JSXConditionalWrappers'

export interface SpinnerProps {
	size?: number
	overlay?: boolean
}

export const Spinner = ({ size = 50, overlay = false }: SpinnerProps): Element => {
	return (
		<div
			className={{
				'spinner flex flex-column items-center justify-center w-full h-full text-center': true,
				'spinner--overlay absolute top-0 left-0 z-spinner rounded-inherit': overlay,
				'my-5': !overlay
			}}
			aria-label="Načítání…"
		>
			<ConditionalWrappers
				wrappers={[
					overlay && ((children: ReactNode) => <div className="sticky h-0 inset-y-[50vh] m-10">{children}</div>),
					overlay && ((children: ReactNode) => <div className="-translate-y-1/2">{children}</div>)
				]}
			>
				<Fragment>
					<svg width={size} height={size} viewBox="0 0 50 50" className="spinner__spinner">
						<circle className="spinner__circle" fill="none" strokeWidth="25" cx="25" cy="25" r="12.5" />
					</svg>
				</Fragment>
			</ConditionalWrappers>
		</div>
	)
}

export const getSpinnerProps = (element: Element) => {
	const spinnerSize = element.getAttribute('data-naja-spinner-size')

	return {
		size: spinnerSize ? parseInt(spinnerSize) : undefined,
		overlay: true
	}
}
