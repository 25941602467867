import Control from '@peckadesign/pd-naja/dist/utils/Control'
import tippy from 'tippy.js'

class TooltipControl implements Control {
	initialize(context: Element | Document): void {
		const tippyElements = context.querySelectorAll('.js-tooltip')

		tippy(tippyElements, {
			maxWidth: 250,
			allowHTML: true,
			offset: [0, 2]
		})
	}
}

export default new TooltipControl()
