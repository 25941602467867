import Control from '@peckadesign/pd-naja/dist/utils/Control'
import { CheckboxGroup } from '@/js/App/CheckboxGroup'

class CheckboxGroupControl implements Control {
	public initialize(context: Element | Document): void {
		const groups = context.querySelectorAll<HTMLElement>('.js-checkbox-group')

		groups.forEach((group) => new CheckboxGroup(group))
	}
}

export default new CheckboxGroupControl()
