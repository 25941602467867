class ScrollbarWidth {
	private readonly element: HTMLElement
	private _width: number

	public constructor() {
		this.element = document.createElement('div')

		this.prepareDOM()
		this._width = this.measure()

		const observer = new ResizeObserver(() => {
			this.updatewidth()
		})
		observer.observe(this.element)
	}

	public get width(): number {
		return this._width
	}

	private measure(): number {
		return this.element.offsetWidth - this.element.clientWidth
	}

	private updatewidth(): void {
		this._width = this.measure()
		document.documentElement.style.setProperty(`--scrollbar-width`, `${this._width}px`)
	}

	private prepareDOM(): void {
		this.element.style.position = 'absolute'
		this.element.style.top = '0px'
		this.element.style.left = '0px'
		this.element.style.visibility = 'hidden'
		this.element.style.width = '200px'
		this.element.style.height = '150px'
		this.element.style.overflow = 'scroll'

		document.body.appendChild(this.element)
	}
}

export default new ScrollbarWidth()
