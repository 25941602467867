import Control from '@peckadesign/pd-naja/dist/utils/Control'
import { EqualHeightItem } from '@/js/App/EqualHeightItem'

class EqualHeightsControl implements Control {
	private equalHeightItems: EqualHeightItem[] = []

	public constructor() {
		const categoryListEqMaxCols2 = new EqualHeightItem('.js-eq-category-list--2', [
			{
				mediaQuery: window.matchMedia('(min-width: 576px)'),
				selectorList: ['.js-eq-category-list__heading'],
				count: 2
			}
		])
		const categoryListEqMaxCols3 = new EqualHeightItem('.js-eq-category-list--3', [
			{
				mediaQuery: window.matchMedia('(min-width: 576px) and (max-width:767.98px)'),
				selectorList: ['.js-eq-category-list__heading'],
				count: 2
			},
			{
				mediaQuery: window.matchMedia('(min-width: 768px)'),
				selectorList: ['.js-eq-category-list__heading'],
				count: 3
			}
		])

		const productListEqMaxCols2 = new EqualHeightItem('.js-eq-product-list--2', [
			{
				mediaQuery: window.matchMedia('(min-width: 768px)'),
				selectorList: ['.js-eq-product-list__heading'],
				count: 2
			}
		])
		const productListEqMaxCols3 = new EqualHeightItem('.js-eq-product-list--3', [
			{
				mediaQuery: window.matchMedia('(min-width: 768px) and (max-width: 991.98px)'),
				selectorList: ['.js-eq-product-list__heading'],
				count: 2
			},
			{
				mediaQuery: window.matchMedia('(min-width: 992px)'),
				selectorList: ['.js-eq-product-list__heading'],
				count: 3
			}
		])

		const dropdownEq = new EqualHeightItem('.js-eq-dropdown', [
			{
				selectorList: ['.js-eq-dropdown__eq']
			}
		])

		this.equalHeightItems.push(categoryListEqMaxCols2)
		this.equalHeightItems.push(categoryListEqMaxCols3)
		this.equalHeightItems.push(productListEqMaxCols2)
		this.equalHeightItems.push(productListEqMaxCols3)
		this.equalHeightItems.push(dropdownEq)
	}

	public initialize(context: Element | Document): void {
		this.equalHeightItems.forEach((equalHeightItem) => {
			equalHeightItem.equalize(context)
		})
	}
}

export default new EqualHeightsControl()
