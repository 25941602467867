import { Extension, Naja, SuccessEvent } from 'naja/dist/Naja'
import { CollapsableEvent } from 'collapsable.js/dist/Collapsable'
import { HTMLCollapsableItem } from 'collapsable.js/src/CollapsableItem'
import { InteractionEvent } from 'naja/dist/core/UIHandler'

declare module 'naja/dist/Naja' {
	interface Options {
		filterItem?: HTMLCollapsableItem
	}
}

export class ParamFilterExtension implements Extension {
	private filterItemSelector = '.js-param-filter__item'

	public initialize(naja: Naja): void {
		naja.uiHandler.addEventListener('interaction', this.onInteraction.bind(this))
		naja.addEventListener('success', this.onSuccess.bind(this))

		const filterItems = document.querySelectorAll<HTMLCollapsableItem>(this.filterItemSelector)

		filterItems.forEach((item) => {
			item.addEventListener('change', (event) => {
				if (event.target && (event.target as Element).matches('[type="checkbox"]')) {
					this.onCheckboxChanged(event)
				}
			})

			item.addEventListener('collapse.collapsable', this.onCollapsed.bind(this))
		})
	}

	private onSuccess(event: SuccessEvent): void {
		const { filterItem } = event.detail.options

		if (filterItem) {
			filterItem.dataset.filterChanged = ''
			filterItem.collapsableItem.collapse(event, null, false)
		}
	}

	private onInteraction(event: InteractionEvent): void {
		const filterItem = event.detail.element.closest<HTMLCollapsableItem>(this.filterItemSelector)

		if (!filterItem) {
			return
		}

		const changed = this.getChangedItemsFromDataset(filterItem)

		if (changed.size === 0) {
			event.preventDefault()
			filterItem.collapsableItem.collapse(event, null, false)
		} else {
			event.detail.options.filterItem = filterItem
		}
	}

	private onCheckboxChanged(event: Event): void {
		if (!event.target || !event.currentTarget) {
			return
		}

		const itemElement = event.currentTarget as HTMLElement
		const checkbox = event.target as HTMLInputElement
		const changed = this.getChangedItemsFromDataset(itemElement)

		if (!changed.has(checkbox.value)) {
			changed.add(checkbox.value)
		} else {
			changed.delete(checkbox.value)
		}

		itemElement.dataset.filterChanged = JSON.stringify(Array.from(changed))
	}

	private onCollapsed(event: CollapsableEvent): void {
		const itemElement = event.currentTarget as HTMLElement
		const changed = this.getChangedItemsFromDataset(itemElement)

		if (changed.size) {
			itemElement.querySelector<HTMLButtonElement>('[type="submit"]')?.dispatchEvent(new Event('click'))
		}
	}

	private getChangedItemsFromDataset(item: HTMLElement | null): Set<string> {
		if (!item) {
			return new Set()
		}

		const dataFilterChanged = (item as HTMLElement).dataset.filterChanged

		return dataFilterChanged ? new Set(JSON.parse(dataFilterChanged)) : new Set()
	}
}
