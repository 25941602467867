import naja from 'naja'
import { CategoryPillsExtension } from '@/js/ui/naja/CategoryPillsExtension'
import { FacultyGuideExtension } from '@/js/ui/naja/FacultyGuideExtension'
import { ParamFilterExtension } from '@/js/ui/naja/ParamFilterExtension'
import { AjaxModalExtension, SpinnerExtension } from '@peckadesign/pd-naja'
import { PdModalNajaAdapter } from '@peckadesign/pd-modal'
import { getSpinnerProps, Spinner } from '@/js/App/Spinner'
import { modal } from '@/js/App/PdModal'

import '@/js/ui/naja/snippetCacheFix' // https://github.com/naja-js/naja/issues/368

naja.registerExtension(new CategoryPillsExtension())
naja.registerExtension(new FacultyGuideExtension())
naja.registerExtension(new ParamFilterExtension())
naja.registerExtension(new SpinnerExtension(Spinner, getSpinnerProps))
naja.registerExtension(
	new AjaxModalExtension(
		new PdModalNajaAdapter(modal, ['snippet--pdModalTitle', 'snippet--pdModalPerex', 'snippet--pdModal'])
	)
)

window.naja = naja

// We must attach Naja to window load event.
if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja))
} else {
	naja.initialize()
}
