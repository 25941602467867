export type EqualHeightRange = {
	mediaQuery?: MediaQueryList
	selectorList: string[]
	count?: number
}

export class EqualHeightItem {
	private selector: string
	private resetSelector: string

	private ranges: EqualHeightRange[]

	private debounce: any

	constructor(selector: string, ranges: EqualHeightRange[]) {
		this.selector = selector
		this.ranges = ranges

		let resetSelectorArray: string[] = []
		this.ranges.forEach((range) => {
			resetSelectorArray = [
				...new Set([...resetSelectorArray, ...range.selectorList.map((selector) => `${this.selector} ${selector}`)])
			]
		})

		this.resetSelector = resetSelectorArray.join(',')

		window.addEventListener('resize', () => {
			this.debounce = setTimeout(() => {
				clearTimeout(this.debounce)
				this.equalize()
			}, 150)
		})
	}

	public equalize(context?: Element | Document): void {
		context = context || document

		const matchedRanges = this.ranges.filter((range) => !range.mediaQuery || range.mediaQuery.matches)

		this.resetElements(context)

		matchedRanges.forEach((range) => this.equalizeRange(range, context as Element | Document)) // as is necessary inside this forEach, probably bug?
	}

	private equalizeRange(range: EqualHeightRange, context: Element | Document): void {
		let items: Element[] = Array.from(context.querySelectorAll(this.selector))

		// `this.selector` could be directly on `context` element (but not on `Document`)
		if (items.length === 0 && context instanceof Element && context.matches(this.selector)) {
			items = [context]
		}

		items.forEach((item) => {
			range.selectorList.forEach((selector) => {
				const elements = Array.from(item.querySelectorAll<HTMLElement>(selector))

				if (range.count) {
					for (let groupStart = 0; elements.length > groupStart; groupStart += range.count) {
						this.getMaxHeight(elements.slice(groupStart, groupStart + range.count), this.setHeight)
					}
				} else {
					this.getMaxHeight(elements, this.setHeight)
				}
			})
		})
	}

	private resetElements(context: Element | Document): void {
		context.querySelectorAll<HTMLElement>(this.resetSelector).forEach((element) => (element.style.height = ''))
	}

	private getMaxHeight(elements: HTMLElement[], callback?: (elements: HTMLElement[], height: number) => void): number {
		let maxHeight = 0

		elements.forEach((element, index) => {
			const value = element.clientHeight
			maxHeight = index ? Math.max(maxHeight, value) : value
		})

		if (callback) {
			callback.call(this, elements, maxHeight)
		}

		return maxHeight
	}

	private setHeight(elements: HTMLElement[], height: number): void {
		elements.forEach((element) => (element.style.height = `${height}px`))
	}
}
