export class CheckboxGroup {
	private context: HTMLElement
	private checkboxes: HTMLInputElement[]
	private checkboxAll: HTMLInputElement

	public constructor(element: HTMLElement) {
		this.context = element

		const checkboxes = Array.from(element.querySelectorAll<HTMLInputElement>('[type="checkbox"]'))
		const checkboxAll = checkboxes.find((checkbox) => checkbox.dataset.checkboxGroupAll)

		if (!checkboxAll) {
			throw new Error('Checkbox group is missing data-checkbox-group-all.')
		}

		checkboxes.splice(checkboxes.indexOf(checkboxAll), 1)

		this.checkboxAll = checkboxAll
		this.checkboxes = checkboxes

		element.addEventListener('change', this.onChange.bind(this))
	}

	private onChange(event: Event | CustomEvent): void {
		if (!event.target || (event instanceof CustomEvent && event.detail?.isTriggered)) {
			return
		}

		const checkbox = event.target as HTMLInputElement

		if (checkbox === this.checkboxAll) {
			this.toggleCheckboxes(this.checkboxes, checkbox.checked)
		}

		if (this.checkboxes.includes(checkbox)) {
			this.toggleCheckboxes(
				[this.checkboxAll],
				this.checkboxes.every((item) => item.checked)
			)
		}
	}

	private toggleCheckboxes(checkboxes: HTMLInputElement[], checked: boolean): void {
		checkboxes.forEach((checkbox) => {
			if (checkbox.checked === checked) {
				return
			}

			checkbox.checked = checked
			checkbox.dispatchEvent(new CustomEvent('change', { bubbles: true, detail: { isTriggered: true } }))
		})
	}
}
