import Control from '@peckadesign/pd-naja/dist/utils/Control'

class FormFloatingControl implements Control {
	public initialize(context: Element | Document): void {
		context.querySelectorAll<HTMLElement>('.form-floating--select').forEach((floating) => {
			floating.addEventListener('change', this.checkEmptyState.bind(floating))
			floating.addEventListener('update', this.checkEmptyState.bind(floating))
		})
	}

	private checkEmptyState(this: HTMLElement, event: Event | CustomEvent): void {
		const select =
			event.target && (event.target as Element).tagName.toLowerCase() === 'select'
				? (event.target as HTMLSelectElement)
				: this.querySelector<HTMLSelectElement>('select')

		if (!select) {
			return
		}

		this.classList.toggle('form-floating--empty', select.selectedOptions[0].text === '')
	}
}

export default new FormFloatingControl()
