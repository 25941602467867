import Control from '@peckadesign/pd-naja/dist/utils/Control'
import Collapsable, { deepMerge } from 'collapsable.js'
import { CollapsableEvent, CollapsableOptions } from 'collapsable.js/dist/Collapsable'
import { HTMLCollapsableItem } from 'collapsable.js/dist/CollapsableItem'
import { DeepPartial } from 'collapsable.js/dist/utils'
import { CollapsableExtLink } from 'collapsable.js/dist/CollapsableExtLink'

type CollapsableGroup = {
	selector: string
	offTapClose: boolean
	options?: DeepPartial<CollapsableOptions>
}

class CollapsableControl implements Control {
	private offTapClose: HTMLCollapsableItem[] = []
	private commonOptions: DeepPartial<CollapsableOptions> = {
		externalLinks: {
			preventDefault: true
		}
	}
	private groups: CollapsableGroup[] = [
		{
			selector: '.js-collapsable:not(.js-collapsable--off-tap-close, [data-collapsable-overlay])',
			offTapClose: false
		},
		{
			selector: '.js-collapsable--off-tap-close, [data-collapsable-overlay]',
			offTapClose: true
		}
	]

	public constructor() {
		this.attachHandlers()
	}

	public initialize(context: Element | Document): void {
		this.groups.forEach((group) => {
			const collapsableElements = context.querySelectorAll<HTMLElement>(group.selector)
			const options: DeepPartial<CollapsableOptions> = deepMerge({}, group.options, this.commonOptions)

			// If off tapping should close, we need to initialize items as separate Collapsable, otherwise
			// when `collapsableElements.length > 1`, off tap would close all items regardless of clicked target.
			if (group.offTapClose) {
				collapsableElements.forEach((element) => {
					const collapsable = new Collapsable(element, options)

					this.initializeElement(element, collapsable)
					this.offTapClose.push(element as HTMLCollapsableItem)
				})
			} else {
				const collapsable = new Collapsable(collapsableElements, options)
				collapsableElements.forEach((element) => {
					this.initializeElement(element, collapsable)
				})
			}
		})
	}

	private initializeElement(element: HTMLElement, collapsable: Collapsable): void {
		element.querySelectorAll(`button.${collapsable.options.classNames.interactiveElement} a`).forEach((anchor) => {
			anchor.addEventListener('click', (event) => event.stopPropagation())
		})
	}

	private attachHandlers(): void {
		// Overlay
		document.body.addEventListener('expand.collapsable', this.handleOverlayClass.bind(this))
		document.body.addEventListener('collapse.collapsable', this.handleOverlayClass.bind(this))

		// Prevent collapse
		document.body.addEventListener('collapse.collapsable', this.checkCollapsability.bind(this))

		// Off tap close
		document.body.addEventListener('click', this.handleOffTapClose.bind(this))
	}

	private handleOverlayClass(event: CollapsableEvent): void {
		const overlayClass = event.target && (event.target as HTMLElement).dataset.collapsableOverlay

		if (overlayClass) {
			const action = event.type === 'expand.collapsable' ? 'add' : 'remove'

			document.body.classList[action](overlayClass)
		}
	}

	private checkCollapsability(event: CollapsableEvent): void {
		if (event.target && (event.target as HTMLElement).classList.contains('js-collapsable--cannot-collapse')) {
			event.preventDefault()
		}
	}

	private handleOffTapClose(event: Event): void {
		if (!event.target) {
			return
		}

		const target = event.target as HTMLElement

		this.offTapClose.forEach((element: HTMLCollapsableItem) => {
			const hash = target.getAttribute('href')?.substring(1)
			const extLinks: CollapsableExtLink[] = element.collapsableItem.collapsable
				.getExtLinkById(hash)
				.filter((extLink: CollapsableExtLink) => extLink.extLink === event.target)

			if (element.contains(event.target as Node) || extLinks.length) {
				return
			}

			element.collapsableItem.collapsable.collapseAll()
		})
	}
}

export default new CollapsableControl()
