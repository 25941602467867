import { Extension, Naja, PayloadEvent, SuccessEvent } from 'naja/dist/Naja'
import { FacultyGuide, FacultyGuideState } from '@/js/App/FacultyGuide'
import { InteractionEvent } from 'naja/dist/core/UIHandler'
import { BuildStateEvent, RestoreStateEvent } from 'naja/dist/core/HistoryHandler'
import { BeforeUpdateEvent } from 'naja/dist/core/SnippetHandler'

declare module 'naja/dist/Naja' {
	interface Options {
		facultyGuide?: boolean
		facultyGuideState?: FacultyGuideState
	}

	interface Payload {
		facultyGuideState?: FacultyGuideState
	}
}

declare module 'naja/dist/core/HistoryHandler' {
	interface HistoryState {
		facultyGuideState?: FacultyGuideState
	}
}

export class FacultyGuideExtension implements Extension {
	private facultyGuide: FacultyGuide | undefined

	public constructor() {
		const facultyGuideElement = document.querySelector<HTMLElement>('.js-faculty-guide')

		if (facultyGuideElement) {
			this.facultyGuide = new FacultyGuide(facultyGuideElement)
			// @ts-ignore
			window.facultyGuide = this.facultyGuide
		}
	}

	public initialize(naja: Naja) {
		if (!this.facultyGuide) {
			return
		}

		naja.uiHandler.addEventListener('interaction', this.checkExtensionEnabled.bind(this))

		naja.addEventListener('payload', this.payloadHandler.bind(this))
		naja.addEventListener('success', this.successHandler.bind(this))

		naja.snippetHandler.addEventListener('beforeUpdate', this.beforeUpdate.bind(this))

		naja.historyHandler.addEventListener('buildState', this.buildState.bind(this))
		naja.historyHandler.addEventListener('restoreState', this.restoreState.bind(this))
	}

	private checkExtensionEnabled(event: InteractionEvent): void {
		const { element, options } = event.detail

		if (element.matches('.js-faculty-guide__button') || element.matches('.js-faculty-guide__link')) {
			options.facultyGuideRequest = true
		}
	}

	private payloadHandler(event: PayloadEvent): void {
		const { options, payload } = event.detail

		if (!options.facultyGuideRequest) {
			return
		}

		if (payload.facultyGuideState) {
			options.facultyGuideState = payload.facultyGuideState
		}
	}

	private successHandler(event: SuccessEvent): void {
		const { options, payload } = event.detail

		if (!options.facultyGuideRequest) {
			return
		}

		if (payload.facultyGuideState) {
			this.facultyGuide?.updateState(payload.facultyGuideState)
		}
	}

	private beforeUpdate(event: BeforeUpdateEvent): void {
		const { content, snippet } = event.detail

		// Prevent emptying the snippet. We always want to keep the last content we had because of animations.
		if (snippet.classList.contains('js-faculty-guide__snippet') && /^\s+$/.test(content)) {
			event.preventDefault()
		}
	}

	private buildState(event: BuildStateEvent): void {
		const { state, options } = event.detail

		if (!options.facultyGuideRequest) {
			return
		}

		state.facultyGuideState = options.facultyGuideState ?? this.facultyGuide?.getState()
	}

	private restoreState(event: RestoreStateEvent): void {
		const { state } = event.detail

		if (state.facultyGuideState) {
			this.facultyGuide?.updateState(state.facultyGuideState)
		}
	}
}
