// Temporary "fix" for https://github.com/naja-js/naja/issues/368
// Basically we save the snippet content from payload and then use this content to store it in cache. By default, Naja
// caches the snippet content potentially modified by `afterUpdate` callback. Then after restore, `afterUpdate` is
// called again, but not on the same data.
import naja from 'naja'
import { BeforeUpdateEvent } from 'naja/dist/core/SnippetHandler'
import { StoreEvent } from 'naja/dist/core/SnippetCache'

const payloadCache: any = {}

naja.snippetHandler.addEventListener('beforeUpdate', (event: BeforeUpdateEvent) => {
	payloadCache[event.detail.snippet.id] = event.detail.content
})

naja.snippetCache.addEventListener('store', (event: StoreEvent) => {
	const { snippets } = event.detail

	for (const key in snippets) {
		if (payloadCache[key]) {
			snippets[key] = payloadCache[key]
			delete payloadCache[key]
		}
	}
})
